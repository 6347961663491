module.exports = new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://mais-precoce-map.alpha.agro.rocks/remoteEntry.js";
        script.onload = () => {
          const module = {
            get: (request) => window["pluginMap"].get(request),
            init: (arg) => {
              try {
                return window["pluginMap"].init(arg);
              } catch (e) {
                console.log("Problem loading remote pluginMap", e);
              }
            },
          };
          resolve(module);
        };
        script.onerror = () => {
  const module = {
    get: () => () => {},
    init: () => () => {},
  };
  resolve(module);
}
        document.head.appendChild(script);
      });